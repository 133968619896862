const toggle: HTMLInputElement | null = document.querySelector('#toggle');

// Handle close by Escape key
document.addEventListener('keyup', (event: KeyboardEvent) => {
	if (toggle?.checked && event.key === 'Escape') {
		toggle.checked = false;
	}
});

// Prevent clicking the current menu link
const links: NodeListOf<HTMLAnchorElement> =
	document.querySelectorAll('.nav-list a');
for (const link of links) {
	if (link && link.href === location.href) {
		link.addEventListener('click', (event: MouseEvent) => {
			event.preventDefault();
			link.blur();
			if (toggle?.checked) {
				toggle.checked = false;
			}
		});
	}
}
