const validationErrors: NodeListOf<HTMLLabelElement> =
	document.querySelectorAll('label.error');

if (validationErrors.length > 0) {
	const [firstValidationError] = validationErrors;
	const wrapper = firstValidationError.parentElement;
	if (wrapper) {
		wrapper.scrollIntoView();
	}
}
