type UrlParameter = [string, string];

const shareLinks: NodeListOf<HTMLAnchorElement> =
	document.querySelectorAll('a.button-share');

const getUrlParameters = (url: string) => {
	const parameters = url
		.split('?')[1]
		.split('&')
		.map((p): UrlParameter => {
			const [parameter, value] = p.split('=');
			return [parameter, decodeURIComponent(value)];
		});

	return new Map(parameters);
};

if (navigator.share) {
	for (const link of shareLinks) {
		// Use Share API and replace links with actual buttons
		const button = document.createElement('button');
		button.type = 'button';
		button.innerHTML = link.innerHTML;
		button.classList.add(...link.className.split(' '));

		// Get share data from link
		const shareData = getUrlParameters(link.href);
		const title = shareData.get('subject');
		const [text, url] = (shareData.get('body') ?? '')
			.split('http')
			.map((value) => value.trim());

		// Set actual share event
		button.addEventListener('click', () => {
			navigator
				.share({
					title,
					text: text || undefined,
					url: url ? `http${url}` : undefined,
				})
				.catch((_: Error) => {
					console.log('Sharing was unsuccessful.');
				});
		});

		// Replace link with button
		link.replaceWith(button);
	}
}
