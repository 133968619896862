type ConnectionStatus = {
	offline: 'offline';
	online: 'online';
};

const connectionStatus: ConnectionStatus = {
	offline: 'offline',
	online: 'online',
};

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		void navigator.serviceWorker
			.register('/serviceworker.js', {
				scope: '/',
			})
			.then((registration) => {
				registration.addEventListener('updatefound', () => {
					// If updatefound is fired, it means that there's
					// a new service worker being installed.
					const installingWorker = registration.installing;
					console.log('⏳ installing');

					if (installingWorker) {
						// You can listen for changes to the installing service worker's
						// state via installingWorker.onstatechange
						installingWorker.addEventListener(
							'statechange',
							(event) => {
								console.log(
									`✅ ${
										(event.currentTarget as ServiceWorker)
											.state
									}`,
								);
							},
						);
					}
				});
			});

		if (navigator.serviceWorker.controller) {
			navigator.serviceWorker.controller.postMessage({
				command: 'trimCaches',
			});
		}
	});
}

const updateOnlineStatus = () => {
	const condition = navigator.onLine
		? connectionStatus.online
		: connectionStatus.offline;

	const submitButtons: NodeListOf<HTMLButtonElement> =
		document.querySelectorAll('[type="submit"]');
	for (const button of submitButtons) {
		if (button) {
			button.disabled = condition === connectionStatus.offline;
		}
	}

	const downloadLinks: NodeListOf<HTMLAnchorElement> =
		document.querySelectorAll('[download]');
	for (const link of downloadLinks) {
		if (condition === connectionStatus.offline) {
			link.setAttribute('aria-disabled', 'true');
		} else {
			link.removeAttribute('aria-disabled');
		}
	}
};

window.addEventListener(connectionStatus.online, updateOnlineStatus);
window.addEventListener(connectionStatus.offline, updateOnlineStatus);

const refreshButton: HTMLButtonElement | null =
	document.querySelector('#refresh');
if (refreshButton) {
	refreshButton.addEventListener('click', () => {
		window.location.reload();
	});
}
