import {tns, type TinySliderSettings} from 'tiny-slider';

const settings: TinySliderSettings = {
	container: '.slider',
	controls: false,
	navPosition: 'bottom',
	autoplay: true,
	autoplayHoverPause: true,
	autoplayButtonOutput: false,
	useLocalStorage: false,
};

if (document.querySelector('.quotes-box')) {
	const slider = tns(settings);
	console.log(slider.getInfo());
}
